import React from "react";
import Navbar2 from "./components/Navbar1";
import Head from "./components/Head";
import Terms from "./components/Terms";

function App() {
  return (
    <div className="App">
      <Head />
      <Navbar2 />
      {/* <Terms/> */}
    </div>
  );
}

export default App;
