import React, { useEffect, useState } from "react";
import axios from "axios";
import CategoryCard from "./CategoryCard";
import "../style/CategoryCard.css";
import Footer from "./Footer";

const CategoryData = () => {
  const [Categories, setCategories] = useState([]);
  const [Permanant, setPermanant] = useState([]);
  const [Result, setResult] = useState();

  console.log(Categories);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://divineyogaforwomen.frappe.cloud/api/method/lms.lms.doctype.lms_category.api.get_category_details",
          {
            method: "GET",
            headers: {
              Authorization: "Token a05d3fcba67eb13:4e965d4f9c818d3",
            },
          }
        );
        const result = await response.json();
        setCategories(result.message.data);
        setResult(true);
      } catch (error) {
        setResult(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (Categories.length > 0) {
      var Initial = [];
      var Second = [];
      const targetTitles = [
        "Fitness Yoga",
        "Weight Loss Challenge",
        "Pregnancy Yoga",
        "General Yoga",
        "Post Diwali Diet - Tamil",
        "Teacher Training Courses",
      ];

      targetTitles.forEach((title) => {
        const result = Categories.find((item) => item.category_titel === title);
        if (result) {
          Initial.push(result);
        }
      });

      Second = Categories.filter(
        (item) => !targetTitles.includes(item.category_titel)
      );
      var final = [...Initial, ...Second];
      setPermanant(final);
    }
  }, [Categories]);

  return (
    <div className="CategoryData">
      <h5 className="Category-heading-one">Category</h5>
      <h4 className="Category-heading-two"> Online Classes</h4>
      <div className="CategoryData-main-div">
        {Permanant.length > 0 ? (
          Permanant.map((item, index) => (
            <div key={index} className="CategoryData-Card-division">
              <CategoryCard item={item} itemRoute={item.route} />
            </div>
          ))
        ) : (
          <h4 className="Category-heading-one">No Data Found</h4>
        )}
      </div>
    </div>
  );
};

export default CategoryData;

// import React, { useEffect, useState } from "react";
// import CategoryCard from "./CategoryCard";
// import "../style/CategoryCard.css";

// const CategoryData = () => {
//   const [Categories, setCategories] = useState([]);
//   const [OnlineClasses, setOnlineClasses] = useState([]);
//   const [OtherPrograms, setOtherPrograms] = useState([]);
//   const [Result, setResult] = useState();
//   console.log(OtherPrograms);
//   console.log(OnlineClasses);

//   const DummyData = [
//     {
//       category_titel: "General Yoga",
//       category_image:
//         "https://divineyogaforwomen.frappe.cloud/files/General yoga.png",
//       description:
//         "Traditional yoga - suitable for all age groups mainly for mental relaxation and stress management - weekly 3 classes.",
//       route: "https://divineyogaforwomen.frappe.cloud/fielder/general-yoga",
//     },
//     {
//       category_titel: "Post Diwali Diet - Tamil",
//       category_image:
//         "https://divineyogaforwomen.frappe.cloud/files/e49982be-8189-4f43-bfcf-0f43166f1bd1.jpg",
//       description: "Starting from Nov 9th 2024 - guidance will be in Tamil.",
//       route:
//         "https://divineyogaforwomen.frappe.cloud/fielder/post-diwali-diet---tamil",
//     },
//     {
//       category_titel: "Fitness Yoga",
//       category_image:
//         "https://divineyogaforwomen.frappe.cloud/files/Fitness Yoga.png",
//       description:
//         "Theme based yoga - suitable for active adults mainly for weight loss & hormonal imbalances - weekly 5 classes.",
//       route: "https://divineyogaforwomen.frappe.cloud/fielder/Fitness-yoga",
//     },
//     {
//       category_titel: "Weight Loss Challenge",
//       category_image:
//         "https://divineyogaforwomen.frappe.cloud/files/Course thumbnail.png",
//       description:
//         "Lose your weight practicing our tailored 'yoga sessions' along with 'balanced diet'.",
//       route:
//         "https://divineyogaforwomen.frappe.cloud/fielder/weight-loss-challenge",
//     },
//     {
//       category_titel: "Teacher Training Courses",
//       category_image: "https://divineyogaforwomen.frappe.cloud/files/TTC.png",
//       description: "Explore our comprehensive yoga teacher training courses.",
//       route:
//         "https://divineyogaforwomen.frappe.cloud/fielder/yoga-teacher-training-course",
//     },
//     {
//       category_titel: "Pregnancy Yoga",
//       category_image:
//         "https://divineyogaforwomen.frappe.cloud/files/Course thumbnail (3).png",
//       description:
//         "Our pregnancy classes are for second and third trimesters - weekly 3 classes. ",
//       route: "https://divineyogaforwomen.frappe.cloud/fielder/pregnancy-yoga",
//     },
//   ];

//   // useEffect(() => {
//   //   const fetchData = async () => {
//   //     try {
//   //       const response = await fetch(
//   //         "https://divineyogaforwomen.frappe.cloud/api/method/lms.lms.doctype.lms_category.api.get_category_details",
//   //         {
//   //           method: "GET",
//   //           headers: {
//   //             Authorization: "Token a05d3fcba67eb13:4e965d4f9c818d3",
//   //           },
//   //         }
//   //       );
//   //       const result = await response.json();
//   //       setCategories(result.message.data);
//   //       setResult(true);
//   //     } catch (error) {
//   //       setResult(false);
//   //     }
//   //   };
//   //   fetchData();
//   // }, []);

//   useEffect(() => {
//     if (DummyData.length > 0) {
//       const onlineClassTitles = [
//         "General Yoga",
//         "Fitness Yoga",
//         "Weight Loss Challenge",
//         "Pregnancy Yoga",
//       ];
//       const otherProgramTitles = [
//         "Post Diwali Diet - Tamil", // updated to match DummyData
//         "Teacher Training Courses", // updated to match DummyData
//       ];

//       const onlineClasses = DummyData.filter((item) =>
//         onlineClassTitles.includes(item.category_titel)
//       );

//       const otherPrograms = DummyData.filter((item) =>
//         otherProgramTitles.includes(item.category_titel)
//       );

//       setOnlineClasses(onlineClasses);
//       setOtherPrograms(otherPrograms);
//     }
//   }, []); // Dependency on DummyData

//   // Ensure DummyData matches category_titel for filtering

//   return (
//     <>
//       <h4 className="Category-heading-one">CATEGORY</h4>
//       <div className="CategoryData-main-div">
//         {Result === true ? (
//           <div className="category-container">
//             {/* Left side: Online Classes */}
//             <div className="category-column">
//               <h5 className="Category-heading-two">Online Classes</h5>
//               {OnlineClasses.length > 0 ? (
//                 OnlineClasses.map((item, index) => (
//                   <div key={index} className="CategoryData-Card-division">
//                     <CategoryCard item={item} itemRoute={item.route} />
//                   </div>
//                 ))
//               ) : (
//                 <p>No Online Classes Found</p>
//               )}
//             </div>

//             {/* Right side: Other Programs */}
//             <div className="category-column">
//               <h5 className="Category-heading-two">Other Programs</h5>
//               {OtherPrograms.length > 0 ? (
//                 OtherPrograms.map((item, index) => (
//                   <div key={index} className="CategoryData-Card-division">
//                     <CategoryCard item={item} itemRoute={item.route} />
//                   </div>
//                 ))
//               ) : (
//                 <p>No Other Programs Found</p>
//               )}
//             </div>
//           </div>
//         ) : (
//           <h4 className="Category-heading-one">No Data Found</h4>
//         )}
//       </div>
//     </>
//   );
// };

// export default CategoryData;

// import React, { useEffect, useState } from "react";
// import CategoryCard from "./CategoryCard";
// import "../style/CategoryCard.css";
// import { Container, Row, Col, Button } from "react-bootstrap";

// const DummyData = [
//   {
//     category_titel: "General Yoga",
//     category_image:
//       "https://divineyogaforwomen.frappe.cloud/files/General yoga.png",
//     description:
//       "Traditional yoga - suitable for all age groups mainly for mental relaxation and stress management - weekly 3 classes.",
//     route: "https://divineyogaforwomen.frappe.cloud/fielder/general-yoga",
//   },
//   {
//     category_titel: "Post Diwali Diet - Tamil",
//     category_image:
//       "https://divineyogaforwomen.frappe.cloud/files/e49982be-8189-4f43-bfcf-0f43166f1bd1.jpg",
//     description: "Starting from Nov 9th 2024 - guidance will be in Tamil.",
//     route:
//       "https://divineyogaforwomen.frappe.cloud/fielder/post-diwali-diet---tamil",
//   },
//   {
//     category_titel: "Fitness Yoga",
//     category_image:
//       "https://divineyogaforwomen.frappe.cloud/files/Fitness Yoga.png",
//     description:
//       "Theme based yoga - suitable for active adults mainly for weight loss & hormonal imbalances - weekly 5 classes.",
//     route: "https://divineyogaforwomen.frappe.cloud/fielder/Fitness-yoga",
//   },
//   {
//     category_titel: "Weight Loss Challenge",
//     category_image:
//       "https://divineyogaforwomen.frappe.cloud/files/Course thumbnail.png",
//     description:
//       "Lose your weight practicing our tailored 'yoga sessions' along with 'balanced diet'.",
//     route:
//       "https://divineyogaforwomen.frappe.cloud/fielder/weight-loss-challenge",
//   },
//   {
//     category_titel: "Teacher Training Courses",
//     category_image: "https://divineyogaforwomen.frappe.cloud/files/TTC.png",
//     description: "Explore our comprehensive yoga teacher training courses.",
//     route:
//       "https://divineyogaforwomen.frappe.cloud/fielder/yoga-teacher-training-course",
//   },
//   {
//     category_titel: "Pregnancy Yoga",
//     category_image:
//       "https://divineyogaforwomen.frappe.cloud/files/Course thumbnail (3).png",
//     description:
//       "Our pregnancy classes are for second and third trimesters - weekly 3 classes. ",
//     route: "https://divineyogaforwomen.frappe.cloud/fielder/pregnancy-yoga",
//   },
// ];

// const CategoryData = () => {
//   const [Categories, setCategories] = useState([]);
//   const [OnlineClasses, setOnlineClasses] = useState([]);
//   const [OtherPrograms, setOtherPrograms] = useState([]);
//   const [Result, setResult] = useState(DummyData.length > 0);

//   useEffect(() => {
//     if (DummyData.length > 0) {
//       setResult(true); // Set Result to true if DummyData is available

//       const onlineClassTitles = [
//         "General Yoga",
//         "Fitness Yoga",
//         "Weight Loss Challenge",
//         "Pregnancy Yoga",
//       ];
//       const otherProgramTitles = [
//         "Post Diwali Diet - Tamil",
//         "Teacher Training Courses",
//       ];

//       const onlineClasses = DummyData.filter((item) =>
//         onlineClassTitles.includes(item.category_titel)
//       );

//       const otherPrograms = DummyData.filter((item) =>
//         otherProgramTitles.includes(item.category_titel)
//       );

//       setOnlineClasses(onlineClasses);
//       setOtherPrograms(otherPrograms);
//     } else {
//       setResult(false); // No data found if DummyData is empty
//     }
//   }, []);

//   return (
//     <>
//       <h4 className="Category-heading-one">CATEGORY</h4>
//       <div className="CategoryData-main-div">
//         <Container fluid>
//           <Row>
//             <Col>
//               {Result === true ? (
//                 <div className="category-container">
//                   {/* Left side: Online Classes */}
//                   <div className="category-column">
//                     <h5 className="Category-heading-two">Online Classes</h5>
//                     <Row>
//                       {OnlineClasses.length > 0 ? (
//                         OnlineClasses.map((item, index) => (
//                           <Col
//                             xs={12}
//                             sm={6}
//                             md={6}
//                             lg={6}
//                             key={index}
//                             className="CategoryData-Card-division"
//                           >
//                             <CategoryCard item={item} itemRoute={item.route} />
//                           </Col>
//                         ))
//                       ) : (
//                         <p>No Online Classes Found</p>
//                       )}
//                     </Row>
//                   </div>

//                   {/* Right side: Other Programs */}
//                   <div className="category-column">
//                     <h5 className="Category-heading-two">Other Programs</h5>
//                     <Row>
//                       {OtherPrograms.length > 0 ? (
//                         OtherPrograms.map((item, index) => (
//                           <Col
//                             xs={12}
//                             sm={6}
//                             md={6}
//                             lg={6}
//                             key={index}
//                             className="CategoryData-Card-division"
//                           >
//                             <CategoryCard item={item} itemRoute={item.route} />
//                           </Col>
//                         ))
//                       ) : (
//                         <p>No Other Programs Found</p>
//                       )}
//                     </Row>
//                   </div>
//                 </div>
//               ) : (
//                 <h4 className="Category-heading-one">No Data Found</h4>
//               )}
//             </Col>
//           </Row>
//         </Container>
//       </div>
//     </>
//   );
// };

// export default CategoryData;
