import React from "react";
import "../style/Carousel.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import review1 from "../assets/review/reviews(1).png";
import review2 from "../assets/review/reviews(2).png";
import review3 from "../assets/review/reviews(3).png";
import review4 from "../assets/review/reviews(4).png";
import review5 from "../assets/review/reviews(5).png";
import review6 from "../assets/review/reviews(6).png";
import review7 from "../assets/review/reviews(7).png";
import review8 from "../assets/review/reviews(8).png";
import review9 from "../assets/review/reviews(3).png";
import { BrowserRouter as Router, Link, Routes, Route } from "react-router-dom";
function CustomPrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        left: "10px",
        zIndex: 1,
        marginTop: "-250px",
      }}
      onClick={onClick}
    />
  );
}

function CustomNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        right: "10px",
        zIndex: 1,
        marginTop: "-250px",
      }}
      onClick={onClick}
    />
  );
}

function Carousel() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    fade: true, // Set fade property to true for fade animation
    // prevArrow: <CustomPrevArrow />,
    // nextArrow: <CustomNextArrow />,
  };

  return (
    <Slider {...settings} className="head">
      <div className="slide-content">
        <img src={review1} alt="Image 1" className="carousel-img1" />
      </div>
      <div className="slide-content">
        <img src={review2} alt="Image 1" className="carousel-img1" />
      </div>
      <div className="slide-content">
        <img src={review3} alt="Image 1" className="carousel-img1" />
      </div>
      <div className="slide-content">
        <img src={review4} alt="Image 1" className="carousel-img1" />
      </div>
      <div className="slide-content">
        <img src={review5} alt="Image 1" className="carousel-img1" />
      </div>
      <div className="slide-content">
        <img src={review6} alt="Image 1" className="carousel-img1" />
      </div>
      <div className="slide-content">
        <img src={review7} alt="Image 1" className="carousel-img1" />
      </div>
      <div className="slide-content">
        <img src={review8} alt="Image 1" className="carousel-img1" />
      </div>
      <div className="slide-content">
        <img src={review9} alt="Image 1" className="carousel-img1" />
      </div>
    </Slider>
  );
}

export default Carousel;

// import React from "react";
// import Slider from "react-slick";
// import "../style/Carousel.css"; // Import your CSS file
// import { Carousel } from "react-responsive-carousel";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
// // import "./TestimonialCarousel.css"; // for custom styles

// const testimonials = [
//   {
//     name: "John Smith",
//     designation: "Client Designation",
//     message: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
//     image: "image_url_1", // Replace with actual image URLs
//     rating: 5,
//   },
//   {
//     name: "Rajesh Kumar",
//     designation: "Yoga Instructor",
//     message: `Thanks to DYFW for providing a detailed and well-executed TTC training. The instructors were very helpful and the 
//     material provided was top-notch. I gained a deep understanding of yoga techniques.`,
//     image: "image_url_2",
//     rating: 4,
//   },
//   {
//     name: "Anjali Patel",
//     designation: "Yoga Trainee",
//     message: `The entire experience with DYFW was wonderful. The syllabus, follow-ups, and support were excellent. 
//     I'm now more confident in my yoga teaching journey.`,
//     image: "image_url_3",
//     rating: 5,
//   },
// ];

// const TestimonialCarousel = () => {
//   return (
//     <Carousel
//       showThumbs={false}
//       infiniteLoop={true}
//       showStatus={false}
//       autoPlay={true}
//       interval={5000}
//     >
//       {testimonials.map((testimonial, index) => (
//         <div key={index} className="testimonial-card">
//           <div className="testimonial-content">
//             <div className="testimonial-avatar">
//               <img src={testimonial.image} alt={testimonial.name} />
//             </div>
//             <div className="testimonial-info">
//               <h3>{testimonial.name}</h3>
//               <p>{testimonial.designation}</p>
//               <div className="testimonial-rating">
//                 {"★".repeat(testimonial.rating)}
//                 {"☆".repeat(5 - testimonial.rating)}
//               </div>
//               <p className="testimonial-text">{testimonial.message}</p>
//             </div>
//           </div>
//         </div>
//       ))}
//     </Carousel>
//   );
// };

// export default TestimonialCarousel;
