import React, { useEffect, useState } from 'react'
import "../style/Terms.css"
const Terms = ({handleTermsClick}) => {

  var state=true
    function Handle(){
      handleTermsClick(!state)
    }

    const termsAndConditions = [
        { id: 1, text: "The content of the pages of this website is subject to change without notice." },

        { id: 2, text: "Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law." },

        { id: 3, text: "Your use of any information or materials on our website and/or product pages is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through our website and/or product pages meet your specific requirements." },

        { id: 4, text: "Our website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance, and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions." },

        { id: 5, text: "All trademarks reproduced in our website which are not the property of, or licensed to, the operator are acknowledged on the website." },

        { id: 6, text: "Unauthorized use of information provided by us shall give rise to a claim for damages and/or be a criminal offense." },

        { id: 7, text: "From time to time our website may also include links to other websites. These links are provided for your convenience to provide further information." },

        { id: 8, text: "You may not create a link to our website from another website or document without Divine Yoga For Women’s prior written consent." },

        { id: 9, text: "Any dispute arising out of use of our website and/or purchase with us and/or any engagement with us is subject to the laws of India." },

        { id: 10, text: "We shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any Transaction, on Account of the Cardholder having exceeded the preset limit mutually agreed by us with our acquiring bank from time to time." }
      ];

      const ContentSharing = [
        { id: 1, text: "Divine Yoga For Women will be capturing pictures of various classes, sessions, and activities along with the teachers-students present at those times. This is for our various publications (print and electronic media)." },

        { id: 2, text: "We respect each person’s privacy and hence if anyone has any objection to be captured in the frame, please feel free to step aside/outside of the camera frame and inform their respective coordinators." }

      ];






      const Payment = [
        { id: 1, text: "Once you make the transfer please send us the payment receipt of your transaction amount for confirmation of your payment – thanks to this small help, we will keep track of your payment." },

        { id: 2, text: "If you cannot pay via bank transfer, we can send a payment link to your email address. For further details please contact us." },

        { id: 3, text: "To book your seat either in our Yoga Teacher Training program or short-term retreat program or Yoga at Home Service the fee has to be paid. Please note that we book the seats according to the chronology of the payment (“first come first served”)." },

        { id: 4, text: "All the transaction fees shall be covered by the student/applicant – it refers to the fees taken by PayPal, Western Union, bank, etc." }

      ];



      const  LoginAccounts = [
        { id: 1, text: "Students must create an account in order to access the service.You may only create an account if:You are at least 13 years old, and if you are less than 18 years old, you have the permission of your parent or guardian;You provide your legal full name, valid email address, zip code, and any other information requested during the signup process." },

        { id: 2, text: "It’s up to you to protect the security of your account and your password. We will not be liable for your losses caused by any unauthorized use of your account. When you set up an account, you will be given the opportunity to set a variety of features, including the price , and discounts for multiple purchases, as well as banking or credit card information and certain communication/privacy preferences. Please see our Privacy Policy for further discussion of such information." }

      ];



      const  AboutSessions=[
        { id: 1, text: "Sessions will be theme based everyday. Instructors who specialized in each area will join based on the theme." },

        { id: 2, text: "Online :-  Monday to Friday  5 AM IST , 6 AM IST , 7 AM IST ( Unisex ) , 9:30 AM IST , 10:30 AM IST , 3:30 PM IST , 6:00 PM IST " },

        { id: 3, text: "Offline :- Monday to Friday - 6 AM, 7 AM, 9:30 AM and 6 PM" },

        { id: 4, text: "Pregnancy :- Monday , Wednesday , Friday 8 AM IST & 6 PM IST " },

        { id: 5, text: "Language: Bilingual in Tamil & English. Duration of all sessions would be 1 hour." },

        { id: 6, text: "No recording / No Compensation class will be given if you miss to attend the session. It will be provided if the session is cancelled by us." },

        { id: 7, text: "No carry forward / no refunds will be provided for any of your personal reasons. Number of unused days cannot be claimed." }
      ]




      const GeneralHealthCondition = [
        { id: 1, text: "Any health-related advice and suggestions given at Divine Yoga For Women should not be used for diagnosing purposes or be substituted for medical advice." },

        { id: 2, text: "Always consult your doctor or qualified health professional on any matters regarding your health. Consult your physician before changing your diet, starting an exercise (yoga) program." },

        { id: 3, text: "It is the sole responsibility of the students/participants to consult a physician prior to and regarding their participation in the programs offered by Divine Yoga For Women." },

        { id: 4, text: "Students/participants are responsible for their own well-being during the class and are advised to practice at their own pace, understanding their limitations. Students/participants are required to inform their teacher if there have been any changes in their medical / health condition which might affect their participation." }

      ];




  return (
    <div className='Terms-Parent-div'>
    <div className='Terms-main-div'>

      <div className="Sub-div-one">
      <h3  className='Terms-h3'>TERMS AND POLICY</h3>
      <button className='Terms-x-button' onClick={Handle}>x</button>
      </div>
      <h5>General Information, Terms & Conditions of
      Divine Yoga For Women:</h5>

      <p className='Terms-Para'>For the purpose of these Terms and Conditions, The term "we", "us", "our" used anywhere on this page shall mean Divine Yoga For Women, whose registered/operational office is 51 Kalidasan Street, Urapakkam Kanchipuram TAMIL NADU 603210 . "you", “your”, "user", “visitor” shall mean any natural or legal person who is visiting our website and/or agreed to purchase from us.</p>

      <h3  className='Terms-h3'>Your use of the website and/or purchase from us are governed by following Terms and Conditions:</h3>
    <ul>
    {termsAndConditions.map((item) => (
      <li className='TERMS-list' key={item.id}>{item.text}</li>
    ))}
    </ul>



    <h3  className='Terms-h3'>Social Media  Content Sharing:</h3>
    <ul>
    {ContentSharing.map((item) => (
      <li className='TERMS-list' key={item.id}>{item.text}</li>
    ))}
    </ul>


    <h3  className='Terms-h3'>Privacy:</h3>
    <p className='Terms-Para'>When you use our services, you’re trusting us with your information. We understand this is a big responsibility and work hard to protect your information and put you in control.</p>



    <h3 className='Terms-h3'>Payment:</h3>
    <ul>
    {Payment.map((item) => (
      <li className='TERMS-list' key={item.id}>{item.text}</li>
    ))}
    </ul>


    <h3 className='Terms-h3'> Divineyogaforwomen.com Login Accounts:</h3>
    <ul>
    {LoginAccounts.map((item) => (
      <li className='TERMS-list' key={item.id}>{item.text}</li>
    ))}
    </ul>



    <h3 className='Terms-h3'>  About sessions::</h3>
    <ul>
    {AboutSessions.map((item) => (
      <li className='TERMS-list' key={item.id}>{item.text}</li>
    ))}
    </ul>


    <h3 className='Terms-h3'>Complimentary sessions:</h3>
    <p className='Terms-Para'>All regular offline group yoga participants can attend Saturday 6 AM IST  and 6 PM IST sessions if u wish. Pls plan and ask the link to join previous day before 9 pm. Your zoom name should be in your original name registered with us.</p>



    <h3 className='Terms-h3'>Note:</h3>
    <p className='Terms-Para'>No sessions on Govt holidays and it will be informed in group previous day. Access to attend any one online batch sessions.</p>


    <h3 className='Terms-h3'>Diet:</h3>
    <p className='Terms-Para'>Avoid junk foods and maintain a balanced diet if you are expecting benefits.If you have registered with Diet, diet plan and instructions will be shared in WhatsApp group. </p>



    <h3 className='Terms-h3'>General Health Condition:</h3>
    <ul>
    {GeneralHealthCondition.map((item) => (
      <li className='TERMS-list' key={item.id}>{item.text}</li>
    ))}
    </ul>


    <h3 className='Terms-h3'>Refund / Cancellation Policy:</h3>
    <p className='Terms-Para'>Divine Yoga for Women has a NO-REFUND policy for all its programs. Please carefully review your order before confirming your purchase. All sales are considered final. No carry forward / no refunds will be provided for any of your personal reasons. Number of unused days cannot be claimed.</p>


    <h6> NOT WITHSTANDING ANYTHING WRITTEN EARLIER, THE RIGHT OF REJECTION OR, TERMINATION FROM THE COURSE, SOLELY RESTS WITH THE MANAGEMENT OF DIVINE YOGA FOR WOMEN.</h6>
    </div>
    </div>
  )
}

export default Terms
