import React from 'react'
import "../style/CategoryCard.css";
const CategoryCard = ({item,itemRoute}) => {
  return (
      <div onClick={() => {window.location.href =itemRoute}} className='CategoryCard-main-div'>

      <h3 className='CategoryCard-heading-3'>{item.category_titel}</h3>

      <img src={item.category_image} alt="" className='CategoryCard-image'/>

      <p className='CategoryCard-paragraph'>{item.description}</p>

      <button className='CategoryCard-Explore-button' onClick={() => {window.location.href =itemRoute}} >Course Details</button>
      
    </div>
  )
}

export default CategoryCard
