import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { BrowserRouter as Router, Link, Routes, Route } from "react-router-dom";
import {
  FaMapMarkerAlt,
  FaEnvelope,
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaPhoneAlt,
  FaWhatsapp,
} from "react-icons/fa";
import "../style/Footer.css";
import Terms from "./Terms";
import logo from "../assets/logo.png";

const Footer = () => {
  const [state, setstate] = useState(false);
  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };

  const handleTermsClick = () => {
    setstate(!state);
  };
  // #ab3c94
  return (
    <footer
      className="text-dark  py-4"
      style={{ backgroundColor: "#301341", padding: 0, margin: 0 }}
    >
      {/* <footer className="text-light py-4" style={{ backgroundColor: "#000" }}> */}
      <Container fluid>
        <Row className="gy-4">
          <Col md={3} xs={6} style={{ textAlign: "left" }}>
            <div className="head5">
              <img
                src={logo}
                alt="Logo"
                style={{ width: "180px", marginBottom: "15px" }} // Adjust logo size
              />
              <p className="para">
                Welcome to Divine Yoga for Women, a space to rejuvenate your
                mind, body, and spirit. Join us for online yoga sessions, detox
                diets, and teacher training. Our goal is to promote holistic
                well-being through expert guidance and community support.
              </p>
            </div>
          </Col>
          {/* Useful Links */}
          <Col md={3} xs={12} style={{ textAlign: "left" }}>
            <h5 className="head5">USEFUL LINKS</h5>
            <br />
            <ul className="list-unstyled" style={{ textAlign: "left" }}>
              <li>
                <Link
                  to="/onlineyoga"
                  className="nav-link"
                  onClick={handleLinkClick}
                >
                  <p>
                    <span
                      style={{
                        fontSize: "23px",
                        paddingTop: "10px",
                        paddingRight: "8px",
                      }}
                    >
                      +
                    </span>
                    Online Yoga
                  </p>
                </Link>
                <hr style={{ color: "#8b8b8b" }} />
              </li>
              <li>
                <Link
                  to="/detox"
                  className="nav-link"
                  onClick={handleLinkClick}
                >
                  <p>
                    <span
                      style={{
                        fontSize: "23px",
                        paddingTop: "10px",
                        paddingRight: "11px",
                      }}
                    >
                      +
                    </span>
                    Detox Diet
                  </p>
                </Link>
                <hr style={{ color: "#8b8b8b" }} />
              </li>
              <li>
                <Link
                  to="/teacher"
                  className="nav-link"
                  onClick={handleLinkClick}
                >
                  <p>
                    <span
                      style={{
                        fontSize: "23px",
                        paddingTop: "10px",
                        paddingRight: "11px",
                      }}
                    >
                      +
                    </span>
                    Teacher Training
                  </p>
                </Link>
                <hr style={{ color: "#8b8b8b" }} />
              </li>
              <li>
                <Link
                  to="/testimonial"
                  className="nav-link"
                  onClick={handleLinkClick}
                >
                  <p>
                    <span
                      style={{
                        fontSize: "23px",
                        paddingTop: "10px",
                        paddingRight: "11px",
                      }}
                    >
                      +
                    </span>
                    testimonials
                  </p>
                </Link>
                <hr style={{ color: "#8b8b8b" }} />
              </li>
              <li>
                <Link to=" " className="nav-link" onClick={handleTermsClick}>
                  <p>
                    <span
                      style={{
                        fontSize: "23px",
                        paddingTop: "10px",
                        paddingRight: "11px",
                      }}
                    >
                      +
                    </span>
                    Terms and Policy
                  </p>
                </Link>
                <hr style={{ color: "#8b8b8b" }} />
              </li>
            </ul>
          </Col>

          {/* teams */}
          <Col md={3} xs={12} style={{ textAlign: "left" }}>
            <h5 className="head5">TEAMS</h5>
            <br />
            <ul className="list-unstyled">
              <li>
                <Link
                  to="/gallery"
                  className="nav-link"
                  onClick={handleLinkClick}
                >
                  <p>
                    <span
                      style={{
                        fontSize: "23px",
                        paddingTop: "10px",
                        paddingRight: "11px",
                      }}
                    >
                      +
                    </span>
                    Gallery
                  </p>
                </Link>
                <hr style={{ color: "#8b8b8b" }} />
              </li>
              <li>
                <Link
                  to="https://www.instagram.com/divine_yoga_for_women/"
                  className="nav-link"
                  onClick={handleLinkClick}
                >
                  <p>
                    <span
                      style={{
                        fontSize: "23px",
                        paddingTop: "10px",
                        paddingRight: "11px",
                      }}
                    >
                      +
                    </span>
                    Community
                  </p>
                </Link>
                <hr style={{ color: "#8b8b8b" }} />
              </li>
            </ul>
          </Col>
          {/* Contact Information */}
          <Col md={3} xs={12} style={{ textAlign: "left" }}>
            <h5
              style={{ color: "white", textAlign: "left", paddingLeft: "5px" }}
            >
              GET IN TOUCH
            </h5>
            <br />
            <p style={{ color: "#ddd" }}>
              <FaMapMarkerAlt />
              &nbsp; 51, Kalidasan Street, <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Revathipuram Main Road, Urapakkam,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Tamil Nadu - 603 210.
            </p>
            <hr style={{ color: "#8b8b8b" }} />
            <p style={{ color: "#ddd" }}>
              <a
                href="tel:94448 00697"
                style={{ color: "white", textDecoration: "none" }}
              >
                <FaPhoneAlt />
                &nbsp; +91-94448 00697,
              </a>
              <a
                href="tel:70108 42898"
                style={{ color: "white", textDecoration: "none" }}
              >
                <span style={{ color: "white" }}>
                  &nbsp;&nbsp;+91-70108 42898
                </span>
              </a>
            </p>
            <hr style={{ color: "#8b8b8b" }} />
            <p style={{ color: "#ddd" }}>
              <a
                style={{ textDecoration: "none", color: "white" }}
                href="mailto:divineyogaforwomen@gmail.com"
              >
                <FaEnvelope /> &nbsp; divineyogaforwomen@gmail.com
              </a>
            </p>
            <hr style={{ color: "#8b8b8b" }} />
            <a
              className="whats-app"
              href="https://wa.me/9176331621"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaWhatsapp className="my-float" />
            </a>
            <a
              className="phone"
              href="tel:9176331621"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaPhoneAlt className="my-float1" />
            </a>
            <div style={{ display: "flex", marginTop: "20px" }}>
              <a href="https://www.facebook.com/divineyogaforwomen">
                <FaFacebook
                  style={{
                    marginRight: "30px",
                    fontSize: "30px",
                    color: "white",
                  }}
                />
              </a>
              <a href="https://www.instagram.com/divine_yoga_for_women/">
                <FaInstagram
                  style={{
                    marginRight: "30px",
                    fontSize: "30px",
                    color: "white",
                  }}
                />
              </a>
              <a href="https://twitter.com/divineyogaforwomen">
                <FaTwitter
                  style={{
                    marginRight: "30px",
                    fontSize: "30px",
                    color: "white",
                  }}
                />
              </a>
            </div>
          </Col>
        </Row>
      </Container>

      {state && <Terms handleTermsClick={handleTermsClick} />}

      <div className="text-center py-3" style={{ color: "#ddd" }}>
        &copy; 2024 Divine yoga | All Rights Reserved
        <p>
          Developed by&nbsp;
          <a
            href="https://vebbox.com/"
            style={{ textDecoration: "none", color: "#FFD0D0" }}
          >
            <i>Vebbox Software Solutions</i>
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
{
  /* <Container>
        <Row> */
}

{
  /* <Col md={4} style={{ textAlign: "left" }}>
            <h5>
              <span>Get In Touch</span>
            </h5>
            <div
              style={{ display: "flex", alignItems: "center" }}
              className="address"
            >
              <FaMapMarkerAlt className="add-icon" />
              <div>
                <p style={{ margin: 0, textAlign: "left", color: "white" }}>
                  51, Kalidasan Street,
                </p>
                <p style={{ margin: 0, textAlign: "left", color: "white" }}>
                  Revathipuram Main Road,
                </p>
                <p style={{ margin: 0, textAlign: "left", color: "white" }}>
                  Urapakkam,
                </p>
                <p style={{ margin: 0, textAlign: "left", color: "white" }}>
                  Tamil Nadu - 603 210.
                </p>
              </div>
            </div>
            <hr style={{ color: "#8b8b8b" }} />
            <p>
              <FaPhoneAlt className="phone-icon" />

              <span style={{ color: "white" }}>
                <a
                  href="tel:94448 00697"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  +91-94448 00697,
                  <a
                    href="tel:70108 42898"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    <span style={{ color: "white" }}>+91-70108 42898</span>
                  </a>
                </a>
              </span>
            </p>

            <hr style={{ color: "#8b8b8b" }} />
            <p>
              <FaEnvelope className="mail-icon" />
              <a
                href="mailto:divineyogaforwomen@gmail.com"
                style={{ color: "white", textDecoration: "none" }}
              >
                divineyogaforwomen@gmail.com
              </a>
            </p>

            <a
              className="whats-app"
              href="https://wa.me/9176331621"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaWhatsapp className="my-float" />
            </a>
            <a
              className="phone"
              href="tel:9176331621"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaPhoneAlt className="my-float1" />
            </a>
          </Col>
        </Row>
      </Container>

      {state && (
        <div>
          <Terms handleTermsClick={handleTermsClick} />
        </div>
      )} */
}
